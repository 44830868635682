import React from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HOME } from "constants/Routes";
import { Markup } from "interweave";
import { getCurrentDate } from "utils/planUtils";

function Footer() {

    const { t } = useTranslation('Layout');

    const isAcc = useSelector((state) => state.AccReducer?.isAcc);
    const isAqt = useSelector((state) => state.AqtReducer?.isAqt);
    const sysDate = useSelector((state) => state.InitDataReducer.initData.sysDate);
    const oepSepTest = useSelector((state) => state.InitDataReducer.initData.oepSepTest);
    //Current Date
    const currentDate = getCurrentDate(sysDate, oepSepTest);
    const currentYear = currentDate.getFullYear();
    const fbBranding = t('fbBranding', { year: currentYear });

    return (
        <footer id="global-footer" tabIndex="-1">
            <div className="foot">

                {!isAcc && !isAqt &&
                    <div className="row footer-links padding-top-2x padding-bottom-2x">
                        <div className="columns small-6 medium-3 large-3 border-right">
                            <h2 className="bottom-1x">
                                {t('quickLinks.title')}
                            </h2>
                            <ul>
                                <li>
                                    <a href={t('quickLinks.toolsAndResourcesUrl')} target="_blank">{t('quickLinks.toolsAndResources')}</a>
                                </li>
                                <li>
                                    <a href={t('quickLinks.insuranceWorksUrl')} target="_blank">{t('quickLinks.insuranceWorks')}</a>
                                </li>
                                <li>
                                    <a href={t('quickLinks.pharmacyUrl')} target="_blank">{t('quickLinks.pharmacy')}</a>
                                </li>
                                <li>
                                    <a href={t('quickLinks.glossaryUrl')} target="_blank">{t('quickLinks.glossary')}</a>
                                </li>
                            </ul>
                        </div>

                        <div className="columns small-6 medium-3 large-3 border-right">
                            <h2 className="footer-inner-text bottom-1x">
                                {t('healthResources.title')}
                            </h2>
                            <ul>
                                <li>
                                    <a href={t('healthResources.aboutHealthCareReformUrl')} target="_blank">{t('healthResources.aboutHealthCareReform')}</a>
                                </li>
                                <li>
                                    <a href={t('healthResources.findLocationUrl')} target="_blank">{t('healthResources.findLocation')}</a>
                                </li>
                                <li>
                                    <a href={t('healthResources.findAgentUrl')} target="_blank">{t('healthResources.findAgent')}</a>
                                </li>
                                <li>
                                    <a href={t('healthResources.finalRateDeterminationUrl')} target="_blank">{t('healthResources.finalRateDetermination')}</a>
                                </li>
                                <li>
                                    <a href={t('healthResources.summaryOfBenefitsUrl')} target="_blank">{t('healthResources.summaryOfBenefits')}</a>
                                </li>
                            </ul>
                        </div>

                        <div className="columns small-6 medium-3 large-3 border-right">
                            <h2 className="footer-inner-text bottom-1x">
                                {t('aboutFloridaBlue.title')}
                            </h2>
                            <ul>
                                <li>
                                    <a href={t('aboutFloridaBlue.aboutUsUrl')} target="_blank">{t('aboutFloridaBlue.aboutUs')}</a>
                                </li>
                                <li>
                                    <a href={t('aboutFloridaBlue.floridaBlueFoundationUrl')} target="_blank">{t('aboutFloridaBlue.floridaBlueFoundation')}</a>
                                </li>
                                <li>
                                    <a href={t('aboutFloridaBlue.careersUrl')} target="_blank">{t('aboutFloridaBlue.careers')}</a>
                                </li>
                                <li>
                                    <a href={t('aboutFloridaBlue.newsroomUrl')} target="_blank">{t('aboutFloridaBlue.newsroom')}</a>
                                </li>
                                <li>
                                    <a href={t('aboutFloridaBlue.blogUrl')} target="_blank">{t('aboutFloridaBlue.blog')}</a>
                                </li>
                            </ul>
                        </div>

                        <div className="columns small-6 medium-3 large-3">
                            <ul className="social-links small-text-center small-top-2x">
                                <li className="padding-left-0x padding-right-0x">
                                    <a
                                        href={t('socialNetwork.facebookLink')}
                                        aria-label={t('socialNetwork.facebookTitle')}
                                        title={t('socialNetwork.facebookTitle')}
                                        className="facebook-btn"
                                        target="_blank"
                                    >
                                        <span className="icon-facebook" aria-hidden="true" />
                                    </a>
                                </li>
                                <li className="padding-left-0x padding-right-0x">
                                    <a
                                        href={t('socialNetwork.twitterLink')}
                                        aria-label={t('socialNetwork.twitterTitle')}
                                        title={t('socialNetwork.twitterTitle')}
                                        className="twitter-btn"
                                        target="_blank"
                                    >
                                        <span className="icon-twitter" aria-hidden="true" />
                                    </a>
                                </li>
                                <li className="padding-left-0x padding-right-0x">
                                    <a
                                        href={t('socialNetwork.linkedinLink')}
                                        aria-label={t('socialNetwork.linkedinTitle')}
                                        title={t('socialNetwork.linkedinTitle')}
                                        className="linkedin-btn"
                                        target="_blank"
                                    >
                                        <span className="icon-linkedin" aria-hidden="true" />
                                    </a>
                                </li>
                                <li className="padding-left-0x padding-right-0x">
                                    <a
                                        href={t('socialNetwork.youtubeLink')}
                                        aria-label={t('socialNetwork.youtubeTitle')}
                                        title={t('socialNetwork.youtubeTitle')}
                                        className="youtube-btn"
                                        target="_blank"
                                    >
                                        <span className="icon-youtube" aria-hidden="true" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }

                <div className="row footer-links padding-top-1x padding-bottom-1x">
                    <div className="columns small-12 large-4 medium-5 padding-1x">
                        <Link
                            onClick={() => window.scrollTo(0, 0)}
                            className="logo"
                            to={HOME}
                            title="Florida Blue"
                            aria-label="Florida Blue Your local Blue Cross Blue Shield"
                        >
                            <span
                                className="icon-logo icon-fb-en-tagline larger-9x small-icon-3x"
                                aria-hidden="true"
                            />
                        </Link>
                    </div>
                    <div className="columns small-12 large-8 large-text-right medium-text-right medium-7">
                        <p>
                            <a href={t('internetPrivacyStatementUrl')} target="_blank">{t('internetPrivacyStatement')}</a>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <a href={t('termsofUseUrl')} target="_blank">{t('termsofUse')}</a>
                        </p>
                    </div>
                </div>

                <div className="row footer-links padding-top-1x padding-bottom-1x">
                    <div className="small-12 large-12 medium-12 columns">
                        <p className="legal">
                            <strong>{t('disclaimerTitle')}</strong>
                            <Markup content={t('disclaimerDescription')} />
                        </p>
                        <p className="legal"><Markup content={t('note1')} /></p>
                        <p className="legal"><Markup content={t('note2')} /></p>
                        <p className="legal"><Markup content={t('note3')} /></p>
                        <p className="legal"><Markup content={t('note4')} /></p>
                    </div>
                </div>

                <div className="row footer-links padding-top-1x padding-bottom-1x">
                    <div className="small-12 large-4 medium-4 columns">
                        <p>{t('fbFormNum')}</p>
                    </div>
                    <div className="small-12 large-8 medium-8 columns large-text-right medium-text-right">
                        <p>{fbBranding}</p>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer;