import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

export class MobileMenuButton extends Component {


  middle = (label, description, icon) => {
    // let cn = (icon) ? "columns small-8" : "columns small-10 text-left";
    if (!description) {
      return (
        <span className="mobile-label">
          <span className="mobile-label-text">{label}</span>
        </span>
      );
    }

    return (
      <span className="mobile-label">
        <span className="mobile-label-text">{label}</span><br />
        <span className="desc">{description}</span>
      </span>
    );

  }


  render() {
    const { iconClass, hasUnread, label, description, hashKey, submenuCallback } = this.props;
    let icon = (iconClass) ? <span aria-hidden="true" className={`mobile-icon ${iconClass}`}/> : undefined;
    let middle = this.middle(label, description, iconClass);
    let unreadIcon = hasUnread ? <span className="icon icon-alert-dot" aria-hidden="true"/> : undefined;

    return (
      <button onClick={() => submenuCallback(hashKey)} className={hasUnread ? 'naked menu-desc-btn unread' : 'naked menu-desc-btn'}>
          {icon}  
          {middle}  
          {unreadIcon}
          <span className="icon-chevron-right" aria-hidden="true"></span>
      </button>
    );
  }

  static propTypes = {
    hasUnread : PropTypes.bool,
    iconClass: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    hashKey: PropTypes.string,
    submenuCallback: PropTypes.func
  };
}