import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { UIModal, ALERTS, UIAlert, LAYOUTS } from "digital-shared-ui";
import { Markup } from "interweave";
import * as Paths from "constants/Routes";
import * as Constants from "constants/Constants";
import { getPlanPaths, getAllPlanListPathname } from "utils/planUtils";
import { isEmpty } from 'utils/commonUtils';
import { resetPlanRatesReducer, setReloadPlanData } from 'actions/PlanRatesAction';
import { resetViewBenefitsReducer } from 'actions/ViewsBenfitsAction';
import NonHealthApplicantModal from '../Cart/NonHealthApplicantModal';

function MarketplaceDisclaimerModal({}, ref) {    
    const { t } = useTranslation('Plans');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const products = useSelector((state) => state.InitDataReducer.initData.products);
    const planRatesObj = useSelector((state) => state.PlanRatesReducer);
    const isOnExchange = useSelector((state) => state.PeopleReducer?.isOnExchange || false);
    const enrGroupsData = useSelector(state => state.OnExchangeReducer[Constants.ENR_GRPS_DATA]);
    const onExCartData = useSelector((state) => state?.CartReducer[Constants.ONEX_CART_ITEMS]) || [];
    const nonHealthApplicant = useSelector((state) => state.OnExchangeReducer?.nonHealthApplicant);
    const [marketplaceDisclaimerModal, setMarketplaceDisclaimerModal] = useState(false);
    const [marketplaceDisclaimerNavData, setMarketplaceDisclaimerNavData] = useState({});
    const [nhApplicantModalOpen, setNHApplicantModalOpen] = useState(false);
    const [nhApplicantRedirectUrl, setNHApplicantRedirectUrl] = useState("");

    const planListURLs = getAllPlanListPathname();

    let isOnExPage = false;
    if (planRatesObj?.QHPProduct?.productName == "QHP" && isOnExchange) {
        isOnExPage = true;
    }

    let displayMegApplicantModal = false;
    // Display MEG Flow Applicant Selection Modal on Cart Page
    if ([Paths.CART].includes(location.pathname) && enrGroupsData !== undefined && enrGroupsData.length > 1 && onExCartData.length > 1 && !nonHealthApplicant) {
        displayMegApplicantModal = true;
    }

    const marketplaceDisclaimerWhitelistPages = [
        Paths.SUBSIDY,
        Paths.SUBSIDY_2,
        Paths.SUBSIDY_3,
        Paths.PLANS_ON,
        Paths.PLANS_COMPARE,
        Paths.PLAN_DETAILS,
        Paths.MULTI_ENROLLMENT_GROUPS,
        Paths.MULTI_ENROLLMENT_GROUPS_2,
        Paths.MULTI_ENROLLMENT_GROUPS_3,
        Paths.CART
    ];

    const marketplaceDisclaimerProtectedPages = [
        Paths.HOME,
        Paths.SDENTAL,
        Paths.ACCIDENT,
        Paths.CRITICAL_ILLNESS,
        Paths.HOSPITAL,
        Paths.INTERNATIONAL,
        Paths.PLANS_COMPARE,
        Paths.PLAN_DETAILS,
        getPlanPaths(Constants.PRODUCT_QHP),
        getPlanPaths(Constants.PRODUCT_QUALIFIED_DENTAL),
        getPlanPaths(Constants.PRODUCT_NON_QUALIFIED_DENTAL),
        getPlanPaths(Constants.PRODUCT_USABLE_LIFE_ACCIDENT),
        getPlanPaths(Constants.PRODUCT_USABLE_LIFE_CRITICAL_ILLNESS),
        getPlanPaths(Constants.PRODUCT_USABLE_LIFE_HOSPITAL),
        Paths.OTHER_PRODUCTS
    ];

    useImperativeHandle(ref, () => ({
        showMarketplaceDisclaimer
    }));

    const toggleMarketplaceDisclaimerModal = () => {
        if (marketplaceDisclaimerModal === true) {
            setMarketplaceDisclaimerModal(false);
        } else {
            setMarketplaceDisclaimerModal(true);
        }
    };

    const toggleNHApplicantModal = () => {
        if (nhApplicantModalOpen === true) {
            setNHApplicantModalOpen(false);
        } else {
            setNHApplicantModalOpen(true);
        }
    };
    
    const handleContinueAction = () => {
        toggleMarketplaceDisclaimerModal(false);
        if (displayMegApplicantModal) {
            toggleNHApplicantModal();
        } else {
            if (planListURLs.includes(marketplaceDisclaimerNavData?.navURL)) {
                dispatch(resetPlanRatesReducer());
                dispatch(resetViewBenefitsReducer());
                dispatch(setReloadPlanData(true));
            }
            navigate(marketplaceDisclaimerNavData?.navURL, { state: marketplaceDisclaimerNavData?.navState });
        }        
    }

    const showMarketplaceDisclaimer = (navURL, navState = {}, event = {}) => {
        if (!isEmpty(event)) {
            event.preventDefault();
        }
        const navData = {
            "navURL": navURL,
            "navState": navState
        };
        if (displayMegApplicantModal) {
            setNHApplicantRedirectUrl(navURL);
        }
        console.log("Marketplace Disclaimer Nav Data", navData);
    
        let showMarketplaceDisclaimer = false;
        if (marketplaceDisclaimerWhitelistPages.includes(location.pathname)) {            
            if (marketplaceDisclaimerProtectedPages.includes(navURL)) {
                if (location.pathname == Paths.PLANS_COMPARE || location.pathname == Paths.PLAN_DETAILS) {
                    if (isOnExPage) {
                        showMarketplaceDisclaimer = true;
                    } else {
                        showMarketplaceDisclaimer = false;
                    }
                } else if (location.pathname == Paths.CART) {
                    if (onExCartData !== undefined && onExCartData.length > 0) {
                        showMarketplaceDisclaimer = true;
                    } else {
                        showMarketplaceDisclaimer = false;
                    }                    
                } else {
                    showMarketplaceDisclaimer = true;
                }
            }   
        }
    
        if (showMarketplaceDisclaimer) {
            setMarketplaceDisclaimerNavData(navData);
            toggleMarketplaceDisclaimerModal();
        } else {
            if (planListURLs.includes(navURL)) {
                dispatch(resetPlanRatesReducer());
                dispatch(resetViewBenefitsReducer());
                dispatch(setReloadPlanData(true));
            }        
            navigate(navURL, { state: navState });
        }
    }

    return (
        <>        
            <UIModal
                isOpen={marketplaceDisclaimerModal}
                onExit={toggleMarketplaceDisclaimerModal}
                aria={{ labelledby: 'marketplace-disclaimer-modal', describedby: "marketplace-disclaimer-modal" }}
                className="modal-container"
            >
                <button
                    aria-label="close dialog"
                    title="close dialog"
                    className="close"
                    onClick={toggleMarketplaceDisclaimerModal}
                />
                <div className="row head collapse">                    
                    <div className="small-12 columns">
                        <h3 id="modal-title" className="hl-large">
                            {t('marketplaceDisclaimerTitle')}
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 medium-12 small-12 columns">
                        <Markup content={t('marketplaceDisclaimerContent')} />
                    </div>
                </div>
                <div className="row">
                    <div className="small-6 medium-6 large-6 columns">
                        <button className="expand secondary" onClick={toggleMarketplaceDisclaimerModal}>
                            {t('marketplaceDisclaimerBtnCancel')}
                        </button>
                    </div>
                    <div className="small-6 medium-6 large-6 columns">
                        <button className="expand" onClick={handleContinueAction}>
                            {t('marketplaceDisclaimerBtnContinue')}
                        </button>
                    </div>
                </div>
            </UIModal>
            <NonHealthApplicantModal
                nhApplicantModalOpen={nhApplicantModalOpen}
                toggleNHApplicantModal={toggleNHApplicantModal}
                nhApplicantRedirectUrl={nhApplicantRedirectUrl}
            />
        </>
    );
};

export default React.forwardRef(MarketplaceDisclaimerModal);