/** Please make sure to append '/' if it is only a main route. (result: BASE_URL + MAIN ROUTE) */
export const HOME = "/";
export const GET_QUOTE = "/get-quote";
export const GET_QUOTE_2 = "/get-quote/2";
export const SUBSIDY = "/subsidy";
export const SUBSIDY_2 = "/subsidy/2";
export const SUBSIDY_3 = "/subsidy/3";
export const PROVIDER = "/provider-search/:product";
export const SELECT = "/select-provider/:product";
export const CART = "/cart";
export const ACCOUNT = "/account";
export const OVERVIEW = "/account/overview";
export const PROFILE = "profile";
export const APPLICATIONS = "/account/applications";
export const INSURANCE_PLANS = "/insurance-plans";
export const SDENTAL = "/sdental-insurance-plans";
export const ACCIDENT = "/accident-insurance-plans";
export const CRITICAL_ILLNESS = "/critical-illness-insurance-plans";
export const HOSPITAL = "/hospital-insurance-plans";
export const INTERNATIONAL = "/international-travel-medical-plans";
export const HELP = "/help";
export const HEALTH_CARE_101 = "/health-care-101";
export const PLANS = "/plans/:type";
export const PLAN_DETAILS = "/plan-details";
export const PLANS_COMPARE = "/plans-compare";
export const PLANS_ON = "/on/plans/qhp";
export const MULTI_ENROLLMENT_GROUPS = "/multi-enrollment-groups";
export const MULTI_ENROLLMENT_GROUPS_2 = "/multi-enrollment-groups/2";
export const MULTI_ENROLLMENT_GROUPS_3 = "/multi-enrollment-groups/3";
export const ONEX_STUB_FLOW = "/onex-stub-flow";
export const ONEX_ENROLLMENT_ERROR = "/onex-enrollment-error";
export const OTHER_PRODUCTS = "/other-products";
export const MWS_QHP_PREVIEW = "/mws-qhp-preview";
export const MWS_QHP_RENEWAL_THANK_YOU = "/mws-qhp-renewal-thank-you";
export const MOT_RENEWAL_THANK_YOU = "/mot-renewal-thank-you";
export const INELGIBLE = "/ineligible";
export const WHAT_TO_EXPECT = "/what-to-expect";
export const CALL_YOU_BACK = "/call-you-back";
export const CALL_YOU_BACK_CONFIRM = "/call-you-back-confirm";
export const CALL_YOU_BACK_ERROR = "/call-you-back-error";
// US673343
//export const MEMBER_PROFILE = "/member/get-quote";
export const MEMBER_PROFILE = "/get-quote";
export const ACC_URL = "/cws/accmt";
export const ACC_VALIDATE = "/cws/accmt/validate/:quoteId";
export const AQT_URL = "/cws/aqtmt";
export const SHOPPING_INELIGIBLE = "/shopping-ineligible";
export const MARKETPLACE_RETURN="/marketplace-return";
export const LOGOUT="/logout";
export const CSR_NUDGE = '/csr-nudge';
export const PREBILL_STUB_FLOW = "/prebill-stub-flow";
export const PROGRESS_STUB_FLOW = "/progress-stub-flow";
export const Health_Insurance_Market_place = "/third-party-disclaimer";
export const UnsubscribedContent = "/unsubscribe"
