import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import uuid from "uuid-random";

import { translator } from "digital-shared-ui";

const resource = {
  backAriaLabel: { en: "back to main menu", es: "volver al menú principal" },
  closeMenu: { en: "close menu", es: "cerrar el menú" },
};

/**
 * Header for a mobile submenu
 */
export class MobileSubMenuHeader extends Component {
  constructor(props) {
    super(props);
    this.translator = translator(resource);
    this.id = uuid();
  }

  componentDidMount() {
    // Move focus to the back (to main menu) button
    document.getElementById(this.id).focus();
  }

  render() {
    const { label, backCallback, closeCallback, className } = this.props;
    // first button is the back button, second button is the close menu button
    return (
      <Fragment>
        <header className="mobile-menu sub-active">
          <div className={`row top-bar ${className}`}>
            <div className="columns small-2">
              <button
                id={this.id}
                onClick={backCallback}
                className="mobile-menu-back naked"
                aria-label={this.translator.translate("backAriaLabel")}
              >
                <span aria-hidden="true" className="icon-arrow-left" />
              </button>
            </div>
            <div className="columns small-8 text-center">
              <span className="hl-small top-bar-sub-heading">{label}</span>
            </div>
            <div className="columns small-2 text-center">
              <button
                onClick={closeCallback}
                aria-label={this.translator.translate("closeMenu")}
                className="naked mobile-menu-toggle"
              >
                <span aria-hidden="true" className="icon-close" />
              </button>
            </div>
          </div>
        </header>
        {this.props.children}
      </Fragment>
    );
  }

  static defaultProps = {
    className: "",
  };

  static propTypes = {
    label: PropTypes.string,
    backCallback: PropTypes.func,
    closeCallback: PropTypes.func,
    className: PropTypes.string,
  };
}