import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UIModal, UIInputField, UIAlert } from "digital-shared-ui";
import { UIInputText, UIInputNumber, UICheckboxGroup } from 'ui-forms';
import { Markup } from "interweave";
import * as RoutePaths from "constants/Routes";
import * as Constants from "constants/Constants";
import { getPlanPaths } from '../../utils/planUtils';
import { formatDate, formatPhoneNumber } from "utils/commonUtils";
import { addProgressInfo, updateProgressInfo, closeProgressCallback } from 'actions/PeopleAction';
import { collectProgressInfo } from "actions/ProgressCollectionAction";
import { getProgressPayload } from "utils/progressUtils";
import { isValidName, validatePhoneNumber } from '../../utils/commonUtils';

function ProgressCallYouBack() {
    const { t, i18n } = useTranslation('Layout');
    let { pathname } = useLocation();
    const dispatch = useDispatch();

    const defaultFormData = {firstName: '',lastName: '', phone:'',consentSelectAll: [], consentFB: [], consentHOI: [], consentFCL: [], consentUSAble: []};
    const peopleReducer = useSelector((state) => state.PeopleReducer);
    const planRatesObj = useSelector((state) => state.PlanRatesReducer);
    const isAcc = useSelector((state) => state.AccReducer?.isAcc);
    const isAqt = useSelector((state) => state.AqtReducer?.isAqt);
    const  inactiveTime = useSelector((state) => state.InitDataReducer.initData.idleModalTimeout || 30000);  //30 secs
    const [callYouBackInfo, setCallYouBackInfo] = useState(defaultFormData);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [consentError, setconsentError] = useState("");
    const [progressCallYouBackModal, setProgressCallYouBackModal] = useState(false);    
    const [submitProgCallBackSuccess, setSubmitProgCallBackSuccess] = useState(false);

    let inactiveTimeout;
    
    let isHealthPlanPage = false;
    if (planRatesObj?.QHPProduct?.productName == "QHP") {
        isHealthPlanPage = true;
    }
    // Pages to display the CallYouBack Modal
    const modalDisplayPages = [
        RoutePaths.PLANS_ON
    ];

    useEffect(() => {
        const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'touchstart'];
        const resetModalDisplayTimeout = () => {
            clearModalDisplayTimeout();
            setModalDisplayTimeout();
        };

        if (!progressCallYouBackModal && !peopleReducer?.isClosedProgressCallBack && ((isHealthPlanPage && pathname == getPlanPaths(Constants.PRODUCT_QHP)) || modalDisplayPages.includes(pathname))) {
            events.forEach(function(name) {
                window.addEventListener(name, resetModalDisplayTimeout, true);
            });
            setModalDisplayTimeout();

            return () => {
                events.forEach(function(name) {
                    window.removeEventListener(name, resetModalDisplayTimeout, true);
                    clearModalDisplayTimeout();
                });
            }
        }
    }, [pathname, isHealthPlanPage, progressCallYouBackModal]);
    
    useEffect(() => {
        setSubmitProgCallBackSuccess(false);
    }, [pathname]);
    
    const setModalDisplayTimeout = () => {
        inactiveTimeout = setTimeout(toggleProgressCallYouBackDialog, inactiveTime);
    };

    const clearModalDisplayTimeout = () => {
        if (inactiveTimeout) clearTimeout(inactiveTimeout);
    };

    const toggleProgressCallYouBackDialog = () => {
        setPhoneError("");
        setconsentError("");
        setFirstNameError("");
        setLastNameError("");
        if (progressCallYouBackModal === true) {
            setProgressCallYouBackModal(false);
        } else {
            setSubmitProgCallBackSuccess(false);
            setProgressCallYouBackModal(true);
        }
    };

    const handleInputChange = (key, value) => {
        if(key === 'consentSelectAll'){
            if(value.length){
                setCallYouBackInfo({
                    ...callYouBackInfo,
                    consentSelectAll: ['true'], 
                    consentFB: ['true'], 
                    consentHOI: ['true'], 
                    consentFCL: ['true'], 
                    consentUSAble: ['true']
                })
            }else{
                setCallYouBackInfo({
                    ...callYouBackInfo,
                    consentSelectAll: [], 
                    consentFB: [], 
                    consentHOI: [], 
                    consentFCL: [], 
                    consentUSAble: []
                })
            }
        }else{
            setCallYouBackInfo({
                ...callYouBackInfo,
                [key]: value,
            })
        }
        return value;
    } 

    const cancelCallYouBackModal = () => {
        console.log("Closed CallYouBack Modal");
        dispatch(closeProgressCallback(true));
        setCallYouBackInfo(defaultFormData);
        toggleProgressCallYouBackDialog();
    }

    const resetSubmitCallBackSuccess = () => {
        setSubmitProgCallBackSuccess(false);
    };

    const submitCallYouBack = () => {        
        if (!isValidateCallYouBackData()) {
            let consentFB = (callYouBackInfo?.consentFB && callYouBackInfo?.consentFB[0]) ? callYouBackInfo?.consentFB[0] : null;
            let consentHOI = (callYouBackInfo?.consentHOI && callYouBackInfo?.consentHOI[0]) ? callYouBackInfo?.consentHOI[0] : null;
            let consentFCL = (callYouBackInfo?.consentFCL && callYouBackInfo?.consentFCL[0]) ? callYouBackInfo?.consentFCL[0] : null;
            let consentUSAble = (callYouBackInfo?.consentUSAble && callYouBackInfo?.consentUSAble[0]) ? callYouBackInfo?.consentUSAble[0] : null;

            dispatch(addProgressInfo(callYouBackInfo));
            dispatch(updateProgressInfo({progressPath: pathname}));

            // Collect Progress Info
            let peopleData = {
                ...peopleReducer,
                firstName: callYouBackInfo.firstName,
                lastName: callYouBackInfo.lastName,
                phone: callYouBackInfo.phone,
                consentFB: consentFB,
                consentHOI: consentHOI,
                consentFCL: consentFCL,
                consentUSAble: consentUSAble,
                progressPath: pathname,
                progressCallBack: true
            };
            
            let progressPayload = getProgressPayload(peopleData);           
            dispatch(collectProgressInfo(progressPayload));
            setSubmitProgCallBackSuccess(true);
            dispatch(closeProgressCallback(true));
            toggleProgressCallYouBackDialog();            
        }
    }    

    const isValidateCallYouBackData = () => {
        let errors = false;
        setPhoneError("");
        setconsentError("");
        setFirstNameError("");
        setLastNameError("");
        if (!callYouBackInfo.firstName) {
            setFirstNameError(`${t('progressCallyouBack.errorRequired')}`);
            errors = true;
        } else { 
            if (!isValidName(callYouBackInfo.firstName)) {
                setFirstNameError(`${t('progressCallyouBack.nameerror')}`);
                errors = true;
            }         
        }
        if (!callYouBackInfo.lastName) {
            setLastNameError(`${t('progressCallyouBack.errorRequired')}`);
            errors = true;
        } else { 
            if (!isValidName(callYouBackInfo.lastName)) {
                setLastNameError(`${t('progressCallyouBack.nameerror')}`);
                errors = true;
            }          
        }
        if (!callYouBackInfo.phone) {
            setPhoneError(`${t('progressCallyouBack.errorRequired')}`);
            errors = true;
        } else {
            if (!validatePhoneNumber(callYouBackInfo.phone)) {
                setPhoneError(`${t('progressCallyouBack.phoneError')}`);
                errors = true;
            }
        }
            if(!callYouBackInfo.consentFB.length && !callYouBackInfo.consentFCL.length && !callYouBackInfo.consentHOI.length && !callYouBackInfo.consentUSAble.length){
                setconsentError(`${t('progressCallyouBack.consentError')}`);
                errors = true;
            }
        return errors;
    }

    const CallYouBackModalForm = () => {        
        return (
            <>                
                <div className="row body">
                    <div className="small-12 columns">
                        <section className="progress-callyouback-modal">
                            <div className="row head">
                                <div className="columns large-12 medium-12 small-12">
                                    <h2>{t('progressCallyouBack.title')}</h2>
                                </div>
                                <div className="columns large-12 medium-12 small-12 smaller-1x">
                                    <p>{t('progressCallyouBack.titleContent')}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="columns small-12 medium-12 large-6">
                                    <UIInputText name='firstName'
                                        label={t('progressCallyouBack.firstName')+'*'}
                                        onValidatedChange={handleInputChange}
                                        labelOutside={true}
                                        labelClassName={"smaller-1x"}
                                        inputClassName={"smaller-1x"}
                                        value={callYouBackInfo.firstName}
                                        placeholder={t('progressCallyouBack.firstName')}
                                        errorMessage={firstNameError}
                                        required={true} />
                                </div>
                                <div className="columns small-12 medium-12 large-6">
                                    <UIInputText name='lastName'
                                        label={t('progressCallyouBack.lastName')+'*'}
                                        onValidatedChange={handleInputChange}
                                        labelOutside={true}
                                        labelClassName={"smaller-1x"}
                                        nputClassName={"smaller-1x"}
                                        value={callYouBackInfo.lastName}
                                        placeholder={t('progressCallyouBack.lastName')}
                                        errorMessage={lastNameError}
                                        required={true} />
                                </div>
                            </div>                   
                            <div className="row">                                                                
                                <div className="columns large-12 medium-12 small-12">
                                    <UIInputText name='phone'
                                        label={t('progressCallyouBack.phone')+'*'}
                                        onValidatedChange={handleInputChange}
                                        labelOutside={true}
                                        labelClassName={"smaller-1x"}
                                        nputClassName={"smaller-1x"}
                                        value={callYouBackInfo.phone}
                                        placeholder={'xxx-xxx-xxxx'}
                                        errorMessage={phoneError}
                                        required={true} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="large-12 medium-12 small-12 columns">
                                    <p className="smaller-1x"><Markup content={t('progressCallyouBack.requiredfield')} /></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="large-12 medium-12 small-12 columns">
                                    <p className="smaller-1x"><Markup content={t('progressCallyouBack.disclaimer')} /></p>
                                </div>
                            </div>
                            <div className="row left-0x">
                                <UICheckboxGroup choices={[{"label": t('progressCallyouBack.selectAll'), "value": "true"}]}
                                    labelClasses='smaller-1x'
                                    errorMessage={consentError}
                                    name='consentSelectAll'
                                    defaultValue={callYouBackInfo.consentSelectAll}
                                    onValidatedChange={handleInputChange} />
                            </div>
                            <div className="row left-0x">
                                <UICheckboxGroup choices={[{"label": t('progressCallyouBack.consentFB'), "value": "true"}]}
                                    labelClasses='smaller-1x'
                                    errorMessage={consentError}
                                    name='consentFB'
                                    defaultValue={callYouBackInfo.consentFB}
                                    onValidatedChange={handleInputChange} />
                            </div>
                            <div className="row left-0x">
                                <UICheckboxGroup choices={[{"label": t('progressCallyouBack.consentHOI'), "value": "true"}]}
                                    labelClasses='smaller-1x'
                                    errorMessage={consentError}
                                    name='consentHOI'
                                    defaultValue={callYouBackInfo.consentHOI}
                                    onValidatedChange={handleInputChange} />
                            </div>
                            <div className="row left-0x">
                                <UICheckboxGroup choices={[{"label": t('progressCallyouBack.consentFCL'), "value": "true"}]}
                                    labelClasses='smaller-1x'
                                    errorMessage={consentError}
                                    name='consentFCL'
                                    defaultValue={callYouBackInfo.consentFCL}
                                    onValidatedChange={handleInputChange} />
                            </div>
                            <div className="row left-0x">
                                <UICheckboxGroup choices={[{"label": t('progressCallyouBack.consentUSAble'), "value": "true"}]}
                                    labelClasses='smaller-1x'
                                    errorMessage={consentError}
                                    name='consentUSAble'
                                    defaultValue={callYouBackInfo.consentUSAble}
                                    onValidatedChange={handleInputChange} />
                            </div>                            
                            <div className="row">                                    
                                <div className="small-6 medium-6 large-6 columns">
                                    <button className="linklike right top-1x" onClick={cancelCallYouBackModal}>{t('progressCallyouBack.cancel')}</button>
                                </div>
                                <div className="small-6 medium-6 large-6 columns">
                                    <button className="expand" onClick={submitCallYouBack}>{t('progressCallyouBack.submit')}</button>
                                </div>                                    
                            </div>                        
                        </section>
                    </div>
                </div>          
            </>
        );
    }    

    return (
        <>
            <UIModal
                isOpen={progressCallYouBackModal}
                onExit={cancelCallYouBackModal}
                aria={{ labelledby: 'modal-title', describedby: "" }}
                ariaHideApp={false}
                className="modal-container"
            >
                <button
                    aria-label="close dialog"
                    title="close dialog"
                    className="close"
                    onClick={cancelCallYouBackModal}
                />
                <div className="row body">
                    <div className="small-12 columns" id="progress-call-you-back-modal">
                        { CallYouBackModalForm() }
                    </div>
                </div>
            </UIModal>
            {submitProgCallBackSuccess &&
                <div className='toast-wrapper show'>
                    <UIAlert icon={true}
                        className='notification toast success'
                        closeButton={{
                            "title": "close",
                            "ariaLabel": "close message",
                            "onClose": resetSubmitCallBackSuccess
                        }}>
                        <h2 className='hl-tiny'>{t('progressCallyouBack.submitCallYouBackSuccessTitle')}</h2>
                        <p>{t('progressCallyouBack.submitCallYouBackSuccessContent')}</p>
                    </UIAlert>
                </div>
            }           
        </>
    )
}

export default ProgressCallYouBack;