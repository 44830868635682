import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import { Markup } from "interweave";

import { UIAccessibilityMenu, UILanguageSwitcher, UIAccordion2, UITabSwitcher, UIMenu, UIDropDown, UIDropDownButton, UIDisclosure, UISkipLinks, ddState, UIModal, UIInputField, UIAlert, LAYOUTS, MOBILE, TABLET, DESKTOP, UISettingsMenu } from "digital-shared-ui";
import { MobileMenu, VIEWS } from "./ReimaginedHeader/MobileMenu";
import { MobileMenuButton } from "./ReimaginedHeader/MobileMenuButton";
import { MobileMenuLink } from "./ReimaginedHeader/MobileMenuLink";
import { UIInputText, UIInputNumber } from 'ui-forms';

import mockInitData from '../../../data/initData.json';
import * as Env from 'constants/Environment';
import * as RoutePaths from "constants/Routes";
import * as Constants from "constants/Constants";
import { useShoppingContext } from 'contexts/ShoppingContext';
import CallYouBack from '../CallYouBack/CallYouBack';
import { resetPlanRatesReducer, setReloadPlanData } from '../../actions/PlanRatesAction';
import { resetViewBenefitsReducer } from '../../actions/ViewsBenfitsAction';
import { isEmpty, getDateStr, formatDate, formatPhoneNumber, parseJson, isValidEmail } from '../../utils/commonUtils';
import { updateHeadBasedOnPathToRoutes, watchdogRouteLogger, getValidResumeProgressPath, isAllowedSaveProgressPath } from "utils/routeUtils";
import { saveFromMenu, addProgressInfo, updateProgressInfo, savePeopleReducerData } from '../../actions/PeopleAction';
import { icmRecordEvent } from "../../utils/icmEventRecord";
import * as Actions from "../../actions/IcmRecordEventAction";
import { cartGTM, startChatGTM } from '../../utils/analyticsUtils';
import { saveMOTData, resetMOTFlag } from "actions/AuthAction";
import { Buffer } from 'buffer';
import { saveProviderSearchReducerData } from '../../actions/ProviderSearchAction';
import ProgressCallYouBack from '../Progress/ProgressCallYouBack';
import SaveProgressPopup from "../Progress/SaveProgressPopup";
import { getPlanPaths, getAllPlanListPathname } from 'utils/planUtils';
import MarketplaceDisclaimerModal from '../Plans/MarketplaceDisclaimerModal'; 

function HeaderReimagined() {

    let location = useLocation();
    let pathname = location.pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(['Layout', 'Acc', 'Aqt', 'MyAccount']);
    const [searchParams] = useSearchParams();
    const { isOnExOep, isOffExOep } = useShoppingContext();
    const marketplaceDisclaimerRef = useRef();

    const sysDate = useSelector((state) => state.InitDataReducer?.initData?.sysDate);
    const oepSepTest = useSelector((state) => state.InitDataReducer?.initData?.oepSepTest);
    const authData = useSelector((state) => state.AuthReducer);
    const isMOT = useSelector((state) => state.AuthReducer?.isMOT);
    const memberAccountPrebillUrl = useSelector((state) => state.InitDataReducer.initData.urls?.memberAccountPrebillUrl || "https://gwprofile.bcbsfl.com/profile/interstitial/?reason=PREBILL");
    const isAuthenticated = useSelector((state) => state.AuthReducer.isAuthenticated);
    const isAuthDataFetchCompleted = useSelector((state) => state.AuthReducer.authDataFetchCompleted);
    const products = useSelector((state) => state.InitDataReducer?.initData?.products);
    const planRatesObj = useSelector((state) => state.PlanRatesReducer);
    const quoteData = useSelector((state) => state.PeopleReducer);
    const isPrebill = useSelector((state)=> state.PeopleReducer.isPrebill);
    const accData = useSelector((state) => state.AccReducer);
    const aqtData = useSelector((state) => state.AqtReducer);
    const cws_gwc_sso_url = useSelector((state) => state.InitDataReducer.initData.authentication?.sso_url);
    const cws_gwc_sso_consumer_logout_endpoint = useSelector((state) => state.InitDataReducer.initData.authentication?.consumer_logout_endpoint);
    const isAcc = useSelector((state) => state.AccReducer?.isAcc);
    const isAqt = useSelector((state) => state.AqtReducer?.isAqt);
    const zipcode = quoteData?.zipcode;
    const isOnExchange = quoteData?.isOnExchange;
    const applicants = quoteData?.applicants;
    const onExReducerData = useSelector((state) => state.OnExchangeReducer);
    const enrGroupsData = useSelector(state => state?.OnExchangeReducer[Constants.ENR_GRPS_DATA]);
    const onExCartData = useSelector((state) => state?.CartReducer[Constants.ONEX_CART_ITEMS]) || [];
    const planComparePreBillData = useSelector((state) => state.PlansCompareReducer?.prebillData?.isPrebill || false);
    const isMultiEnrollmentGroupApplication = enrGroupsData?.length > 0;

    const [isActive, setActive] = useState(true);
    const [toLogout, setToLogout] = useState(false);
    const [activeMobileMenuView, setActiveMobileMenuView] = useState(VIEWS.CLOSED);
    const [mobileSubMenu, setMobileSubMenu] = useState({});

    const cwsPathAccessUrl = ['/account/applications', '/account/overview', '/cart/save/true', '/cart/save/false/ajax', '/dst/profile', '/dst/verify']
    const [homePagePath, setHomePagePath] = useState(RoutePaths.HOME)
    const payload = icmRecordEvent("Chat Initiated");
    const campaignKeycode = useSelector((state) => state.PeopleReducer.keycode || "8339");
    const accountProfileUrl = useSelector((state) => state.InitDataReducer.initData.urls?.accountProfileUrl || {});

    // Five9 Chat Variables
    const chatEnabled = useSelector((state) => state.InitDataReducer.initData.chat?.chatEnabled === "true" ? true : false);
    const callbackEnabled = useSelector((state) => state.InitDataReducer.initData.chat?.callbackEnabled === "true" ? true : false);
    const fnAppId = useSelector((state) => state.InitDataReducer.initData.chat?.five9AppId || "BlueCrossBlueShield of FL - Developer Program");
    const fnScriptUrl = useSelector((state) => state.InitDataReducer.initData.chat?.five9ScriptUrl || "https://live-chat.ps.five9.com/Five9ChatPlugin.js");
    const fnEngConfigId = useSelector((state) => state.InitDataReducer.initData.chat?.five9ConfigIdEnglish || "U65_English");
    const fnEsConfigId = useSelector((state) => state.InitDataReducer.initData.chat?.five9ConfigIdSpanish || "U65_Spanish");
    const fnEngNudgeId = useSelector((state) => state.InitDataReducer.initData.chat?.five9NudgeIdEnglish || "3beaf079-d8ad-46c7-ba39-8496a8a7609c");
    const fnEsNudgeId = useSelector((state) => state.InitDataReducer.initData.chat?.five9NudgeIdSpanish || "fda7315c-74a9-4256-8cff-8b09e0717d97");
    const consumerChannel = useSelector((state) => state.InitDataReducer.initData.chat?.five9ConsumerChannel || "CWS");

    const planListURLs = getAllPlanListPathname();

    // Page to title mapping for sending to Five9
    const pathToTitleMapping = {
        [RoutePaths.HOME]: "Home",
        [RoutePaths.GET_QUOTE]: "Get a Quote",
        [RoutePaths.SDENTAL]: "Dental Landing",
        [RoutePaths.ACCIDENT]: "USAble Life Accident Plans Landing",
        [RoutePaths.CRITICAL_ILLNESS]: "USAble Life Critical Illness Plans Landing",
        [RoutePaths.HOSPITAL]: "USAble Life Hospital Plans Landing",
        [RoutePaths.INTERNATIONAL]: "International Travel Medical Plans",
        [RoutePaths.HEALTH_CARE_101]: "Health Care 101",
        [RoutePaths.OTHER_PRODUCTS]: "Other Products",
        [RoutePaths.WHAT_TO_EXPECT]: "What to Expect",
        [RoutePaths.PLANS_COMPARE]: "Plan Compare",
        [RoutePaths.PLAN_DETAILS]: "Plan Details",
        [RoutePaths.CART]: "Cart",
        [RoutePaths.HELP]: "Support",
        [RoutePaths.MWS_QHP_PREVIEW]: "Prebill Preview"
    }    

    // Add Plan List Pages in Chat Title Mapping
    planListURLs.forEach((url) => {
        pathToTitleMapping[url] = "Plan List";
    });

    // Pages to display the proactive chat
    const proActiveChatPages = [
        //RoutePaths.HOME,
        RoutePaths.GET_QUOTE,
        RoutePaths.CART,
        RoutePaths.WHAT_TO_EXPECT,
        getPlanPaths(Constants.PRODUCT_QHP),
        RoutePaths.PLANS_ON,
        RoutePaths.PLANS_COMPARE,
        RoutePaths.PLAN_DETAILS,
        RoutePaths.MWS_QHP_PREVIEW
    ];

    // Pages to hide the chat link
    const hideHeaderChatPages = [
        RoutePaths.HELP,
        RoutePaths.CALL_YOU_BACK,
        RoutePaths.CALL_YOU_BACK_CONFIRM,
        RoutePaths.CALL_YOU_BACK_ERROR,
        RoutePaths.MWS_QHP_PREVIEW
    ];

    let hideChatLinkPrebillCompare = false;
    if ([RoutePaths.PLANS_COMPARE].includes(pathname) && planComparePreBillData) {
        hideChatLinkPrebillCompare = true;
    }

    let isHealthPlanPage = false;

    if (planRatesObj?.QHPProduct?.productName == "QHP") {
        isHealthPlanPage = true;
    }

    let lang = (i18n.language === "es") ? 'es' : 'eng';

    const [callYouBackModal, setCallYouBackModal] = useState(false);
    const [saveProgressModal, setSaveProgressModal] = useState(false);

    let progressState = searchParams.get('state');

      useEffect(() => {
        // US673343 navigate to memberprebillurl one time
        // console.log('useEffect authData.isMember:', authData.isMember);
        // console.log('useEffect authData.displayedMOT:', authData.displayedMOT);
        // console.log('useEffect isOnExOep:', isOnExOep);
        // console.log('useEffect isOffExOep:', isOffExOep);
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        async function redirectToMemberPrebillUrl() {
            for (let i = 0; i < 2; i++) {
                console.log(`Waiting to redirect in ${i} seconds...`);
                await sleep(i * 1000);
            }
            console.log("Navigate user to memberAccountPrebillUrl, displayedMOT:", authData.displayedMOT);
            window.location.href = memberAccountPrebillUrl + '&locale=' + i18n.language;
        }
         if ((isOnExOep || isOffExOep) && authData.isMember && !authData.displayedMOT) {
             dispatch(saveMOTData(true));
             if (!([RoutePaths.MULTI_ENROLLMENT_GROUPS, RoutePaths.WHAT_TO_EXPECT, RoutePaths.APPLICATIONS].includes(pathname))) {
                 redirectToMemberPrebillUrl();
             }
         }
    }, []);

    useEffect(() => {
        // Close Mobile Menu if navigation and language is changed 
        setActiveMobileMenuView(VIEWS.CLOSED);
    }, [pathname, lang]);

    useEffect(() => {
        // Don't allow member to continue MOT flow if logged in member is not in OEP period
        if (isMOT && !isOffExOep) {
            console.log("Reset MOT Flag")
            dispatch(resetMOTFlag());
        }
    }, []);

    useEffect(() => {
        changeLanguage(searchParams.get('language'));
    }, []);

    useEffect(() => {
        if (cwsPathAccessUrl.indexOf(window.location.pathname) > -1 && mockInitData.GWCAuth) {
            document.getElementById("authform").submit();
        }
    }, []);

    useEffect(() => {
        // Add FiveNive URL
        fiveNiveChatLoadScript(fnScriptUrl);
    }, []);

    // Handle Save Progess Return
    useEffect(() => {
        if (progressState && !isEmpty(progressState)) {
            try {
                //console.log("progressState: ", progressState);
                let decodedProgressState = Buffer.from(progressState, "base64");
                //console.log("decodedProgressState: ", decodedProgressState);
                let jsonObject = parseJson(decodedProgressState);
                if (jsonObject) {
                    let peopleReducerData = jsonObject?.PeopleReducer || {};
                    let providerReducerData = jsonObject?.ProviderSearchReducer || {};
                    let progressPath = getValidResumeProgressPath(peopleReducerData?.progressPath);
                    let progressProduct = peopleReducerData?.progressProduct || "";
                    changeLanguage((peopleReducerData?.progressLanguage == Constants.ESP) ? 'es' : 'en');
                    dispatch(resetPlanRatesReducer());
                    dispatch(resetViewBenefitsReducer());
                    // Populate People Reducer
                    dispatch(savePeopleReducerData(peopleReducerData));
                    // Populate Provider Search Reducer
                    if (!isEmpty(providerReducerData)) {
                        dispatch(saveProviderSearchReducerData(providerReducerData));
                    }
                    dispatch(updateProgressInfo({ progressResume: true }));
                    navigate(progressPath, { state: { progressResume: true, progressProduct: progressProduct } });
                } else {
                    navigate(RoutePaths.HOME);
                }
            } catch (exception) {
                console.log('Progress Received Data Error Exception:', exception.stack);
                navigate(RoutePaths.HOME);
            }
        }
    }, [progressState]);

    useEffect(() => {
        // Expose some global vars for Dynatrace session integration
        exposeGlobalVars();
    }, []);

    useEffect(() => {
        updateHeadBasedOnPathToRoutes(location);
        watchdogRouteLogger(
            location,
            zipcode,
            authData?.USER_ACCT_NUMBER ?? "",
            authData?.CIPID ?? "",
            onExReducerData?.enrollmentResponse?.ffmAppId ?? "",
            onExReducerData?.enrollmentResponse?.sseAppId ?? ""
        );
    }, [location.pathname]);

    useEffect(() => {
        if (isMOT && [...planListURLs, RoutePaths.MEMBER_PROFILE].includes(pathname)) {
            setHomePagePath(pathname)
        } else {
            setHomePagePath(RoutePaths.HOME)
        }

        if (!isAcc && !isAqt) {
            const timer = setTimeout(() => {
                fiveNineChat();
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [lang, pathname, isHealthPlanPage]);

    const exposeGlobalVars = () => {
        const script = document.createElement("script");
        script.innerHTML = `
        var userId = '${authData?.USER_ACCT_NUMBER ?? ""}';
        var cipId = '${authData?.CIPID ?? ""}';
        `
        document.body.appendChild(script);
    };

    const fiveNiveChatLoadScript = (url) => {
        if (chatEnabled) {
            let head = document.getElementsByTagName('head')[0];
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = url;
            head.appendChild(script);
        }
    }

    const fiveNineChat = async () => {
        if (chatEnabled) {
            const elChatWidget = document.getElementById('five9LiveChatWidget');

            let configId = (lang === 'es') ? fnEsConfigId : fnEngConfigId;
            let nudgeId = "";
            if (proActiveChatPages.includes(pathname)) {
                if (pathname == RoutePaths.PLANS_COMPARE || pathname == RoutePaths.PLAN_DETAILS) {
                    if (isHealthPlanPage) {
                        nudgeId = (lang === 'es') ? fnEsNudgeId : fnEngNudgeId;
                    }
                } else {
                    nudgeId = (lang === 'es') ? fnEsNudgeId : fnEngNudgeId;
                }
            }

            let pageTitle = (pathToTitleMapping[pathname]) ? pathToTitleMapping[pathname] : "Other Page"
            let priorityCode = "not set";

            let fiveNineCode = `
                function callback(event) {
                    try {
                    switch (event.type) {
                        case 'businesshours':
                            let businessHours = event.businesshours.info;
                            //console.log("Business Hours:", businessHours);
                            let chatButton = document.getElementById('five9-chat-button');
                            let callyoubackButton = document.getElementById('five9-call-you-back');
                            if(businessHours.agentsLoggedIn) {
                                if (chatButton) {
                                chatButton.classList.remove('hide');
                                }
                                if (callyoubackButton) {
                                callyoubackButton.classList.add('hide');
                                }
                            } else {
                                if (callyoubackButton) {
                                callyoubackButton.classList.remove('hide');
                                }
                                if (chatButton) {
                                chatButton.classList.add('hide');
                                }
                            }
                        break;
                        default:
                            //console.log("default")
                        break;
                    }
                    } catch (exception) {
                        console.error('Exception caught in event handler: ' + exception.stack);
                    }
                }
                var fiveNineOptions = { 
                    "appId":"${fnAppId}",
                    "configId":"${configId}",
                    "nudgeId": "${nudgeId}",
                    "mobileNudgeId": "",
                    "headless": true,
                    "debug": false,
                    "attributes": [
                        {
                        "Chat.Consumer_Channel": "${consumerChannel}"
                        },
                        {
                        "Chat.Visitor_Id": "${Env.USER_TOKEN}"
                        },
                        {
                        "Chat.profile_id": "${Env.USER_TOKEN}"
                        },
                        {
                        "Chat.Key_Code": "${campaignKeycode}"
                        },
                        {
                        "Chat.Priority_Code": "${priorityCode}"
                        },
                        {
                        "Chat.Page_Name": "${pageTitle}"
                        }
                    ],
                };
                fiveNineOptions.callback = callback;
                Five9ChatPlugin(fiveNineOptions);
            `;
            if (elChatWidget) {
                // already initialized, use Five9ChatSetOption
                await Five9ChatSetConfigId(configId);
                Five9ChatSetOption("headless", true);
                Five9ChatSetOption("nudgeId", nudgeId);
                Five9ChatSetOption("attributes", Five9ChatMergeAttributes(Five9ChatGetOption('attributes'), [{ "Chat.Page_Name": pageTitle }]));
                //Five9ChatSetOption("callback", callback);
            } else {
                // not initialized, push full fiveNineOptions into Five9ChatPlugin
                const scriptCode = document.createElement('script');
                scriptCode.type = 'text/javascript';
                scriptCode.async = true;
                scriptCode.id = "five9";
                scriptCode.innerHTML = fiveNineCode;
                document.body.appendChild(scriptCode);
            }
        }
    }

    const startFiveNineChat = () => {
        // Trigger Event tracking
        startChatGTM();
        dispatch(Actions.icmRecordEvent(payload));
        document.getElementById("five9OpenChatButton").click();
    };

    const toggleDialogVisibility = () => {
        // Trigger Event tracking
        dispatch(Actions.icmRecordEvent(payload));
        if (callYouBackModal === true) {
            setCallYouBackModal(false);
        } else {
            setCallYouBackModal(true);
        }
    };

    const toggleSaveProgressDialog = () => {
        if (saveProgressModal === true) {
            setSaveProgressModal(false);
        } else {
            setSaveProgressModal(true);
        }
    };

    const CallYouBackPopup = () => {
        return (
            <UIModal
                isOpen={callYouBackModal}
                onExit={toggleDialogVisibility}
                aria={{ labelledby: 'modal-title', describedby: "" }}
                ariaHideApp={false}
                className="modal-container"
                overlayClassName="callYouBackOverlay"
            >
                <button
                    aria-label="close dialog"
                    title="close dialog"
                    className="close"
                    onClick={toggleDialogVisibility}
                />
                <div className="row body">
                    <div className="small-12 columns" id="call-you-back-pupup">
                        <CallYouBack isPopup="true" setCallYouBackModal={setCallYouBackModal} />
                    </div>
                </div>
            </UIModal>
        );
    }

    const getAccAgentContent = (agentInfo) => {

        let agentContactInfo = agentInfo?.contactInfo;
        let contactType = t("accCallTitle", { ns: 'Acc' });
        let contactData = agentContactInfo?.phone ? formatPhoneNumber(agentContactInfo?.phone) : '';

        if (agentContactInfo?.preferredContactMethod == 'E') {
            contactType = t("accEmailTitle", { ns: 'Acc' });
            contactData = agentContactInfo?.email ? agentContactInfo?.email : '';
        }

        return (
            <>
                {agentInfo?.firstName ? agentInfo?.firstName : ''} {agentInfo?.lastName ? agentInfo?.lastName : ''} | {t("accLicenseTitle", { ns: 'Acc' })} {agentInfo?.licenseNum ? agentInfo?.licenseNum : ''} | {t("accHelpTitle", { ns: 'Acc' })} {contactType} <strong>{contactData}</strong>
            </>
        );
    }

    const getAqtAgentContent = (agentInfo) => {

        let agentContactInfo = agentInfo?.contactInfo;
        let contactType = t("aqt.content.call-title", { ns: 'Aqt' });
        let contactData = agentContactInfo?.phone ? formatPhoneNumber(agentContactInfo?.phone) : '';

        if (agentContactInfo?.preferredContactMethod == 'E') {
            contactType = t("aqt.content.email-title", { ns: 'Aqt' });
            contactData = agentContactInfo?.email ? agentContactInfo?.email : '';
        }

        return (
            <>
                {agentInfo?.firstName ? agentInfo?.firstName : ''} {agentInfo?.lastName ? agentInfo?.lastName : ''} | {t("aqt.content.license-title", { ns: 'Aqt' })} {agentInfo?.licenseNum ? agentInfo?.licenseNum : ''} | {t("aqt.content.help-title", { ns: 'Aqt' })} {contactType} <strong>{contactData}</strong>
            </>
        );
    }

    const onLanguageChange = () => {
        const switchLangTo = (localStorage['i18nextLng'] === "en") ? "es" : "en";
        changeLanguage(switchLangTo);
        const allPlanListPath = getAllPlanListPathname();
        if (allPlanListPath.includes(location.pathname)) {
            dispatch(resetPlanRatesReducer());
        }
        // only force reload page when on HOME page (fix for UISettingsMenu language switching)
        if (RoutePaths.HOME == pathname) {
            window.location.href = RoutePaths.HOME + "?language=" + switchLangTo;
        }
    };

    const handleAction = (event, key) => {
        event.preventDefault();

        if (key === "dental") {
            marketplaceDisclaimerRef.current.showMarketplaceDisclaimer(RoutePaths.SDENTAL);
        } else if (key === "health") {
            if (zipcode !== "" && applicants.length > 0) {
                dispatch(resetPlanRatesReducer());
                dispatch(resetViewBenefitsReducer());
                dispatch(setReloadPlanData(true));
                if (isOnExchange) {
                    let isNextGroupPresent = false;
                    if (enrGroupsData.length > 1) {
                        isNextGroupPresent = true;
                    }
                    let navState = {
                        productSegment: Constants.PRODUCT_SEGMENT_HEALTH,
                        selectedProduct: products.find((product) => product.productName.toUpperCase() === Constants.PRODUCT_QHP && product.productClassName.toUpperCase() === Constants.PRODUCT_CLASS_HEALTH.toUpperCase()),
                        isOnExchange: true,
                        isMultiEnrollmentGroupApplication,
                        isNextGroupPresent
                    }
                    navigate(RoutePaths.PLANS_ON, { state: navState });
                } else {
                    let navState = {
                        productSegment: Constants.PRODUCT_SEGMENT_HEALTH,
                        selectedProduct: products.find((product) => product.productName.toUpperCase() === Constants.PRODUCT_QHP && product.productClassName.toUpperCase() === Constants.PRODUCT_CLASS_HEALTH.toUpperCase()),
                        isOnExchange: false
                    }
                    navigate(getPlanPaths(Constants.PRODUCT_QHP), { state: navState });
                }

            } else {
                dispatch(saveFromMenu(true))
                navigate(RoutePaths.GET_QUOTE, { state: { productSegment: Constants.PRODUCT_SEGMENT_HEALTH } });
            }
        } else if (key === "accident") {
            marketplaceDisclaimerRef.current.showMarketplaceDisclaimer(RoutePaths.ACCIDENT);
        } else if (key === "ci") {
            marketplaceDisclaimerRef.current.showMarketplaceDisclaimer(RoutePaths.CRITICAL_ILLNESS);
        } else if (key === "hi") {
            marketplaceDisclaimerRef.current.showMarketplaceDisclaimer(RoutePaths.HOSPITAL);
        }
    }

    const handleLogin = () => {
        // US673343, TODO: cleanup
        /* if (isOnExOep || isOffExOep) {
            window.location.href = memberAccountPrebillUrl + '&locale=' + i18n.language;
        } else {
            navigate(RoutePaths.OVERVIEW);
        } */
        navigate(RoutePaths.OVERVIEW);
    }

    const handleLogout = () => {
        console.log("handleLogout");
        setToLogout(true);
        const targetURL = `${cws_gwc_sso_url}${cws_gwc_sso_consumer_logout_endpoint}?TARGET=${window.location.origin}/cws/sso/logout`;
        window.location.href = targetURL;
    }

    const handleCart = () => {
        cartGTM();
        navigate(RoutePaths.CART)
    };

    const clickCallback = (event) => {
        // Close header drop downs if a header button was clicked
        let button = event.target.closest("button");
    
        if (button) {
            //setMyAccountNavObj({ ddStateC: ddState("dd3c", false)});          
        }
    };    

    const renderDesktopNav = t => {
        return (
            <Fragment>
                <UISkipLinks links={[{ "label": t("skipHeader", { ns: 'Layout' }), "selector": "body" }]} />
                <div className="header-reimagined show-for-large">
                    <header className="header-support">
                        <nav>
                            <div className="support">
                                <div className="row">
                                    <div className="columns small-12 medium-5 large-5" onClick={clickCallback}>
                                        <UISettingsMenu includeLanguage={false} includeStandard={false} contactUrl="" />
                                        {(!isAcc || (isAcc && accData?.response?.supportedLangs.indexOf("en,es") !== -1)) &&
                                            <span className="global-border" aria-hidden="true">&nbsp;</span>
                                        }
                                        {(!isAcc || (isAcc && accData?.response?.supportedLangs.indexOf("en,es") !== -1)) &&
                                            <UILanguageSwitcher
                                                className="global language-toggle"
                                                altLang={(localStorage['i18nextLng'] === "en") ? "es" : "en-us"}
                                                onLanguageChange={onLanguageChange}
                                            />
                                        }
                                        <span className="global-border" aria-hidden="true">&nbsp;</span>
                                        {isAcc ? (
                                            <span className="acc smaller-3x">
                                                {getAccAgentContent(accData.response.agentInfo)}
                                            </span>
                                        ) : isAqt ? (
                                            <span className="aqt smaller-3x">
                                                {getAqtAgentContent(aqtData.response.agentInfo)}
                                            </span>
                                        ) : (
                                            <span className="global language-toggle">
                                                {t('needhelp')} <a href="tel:1-855-714-8894"><strong>1-855-714-8894</strong></a>
                                            </span>
                                        )
                                        }                                            
                                    </div>
                                    <div className="columns small-12 medium-7 large-7 text-right">
                                        <button onClick={handleCart} className="button naked global icon">
                                            <span aria-hidden="true" className="icon-shopping-cart"></span>
                                            <span>{t('cart')}</span>
                                        </button>
                                        <span className="global-border" aria-hidden="true">&nbsp;</span>
                                        <a href={t('findADoctorUrl')} target="_blank" className="global naked icon">
                                            <span className="icon-doctor" aria-hidden="true" />
                                            <span>{t('findADoctor')}</span>
                                        </a>
                                        <span className="global-border" aria-hidden="true">&nbsp;</span>
                                        {!isAcc && !isAqt &&
                                            <>
                                            <Link to={RoutePaths.HELP} className="global icon">
                                                <span aria-hidden="true" className="icon-question-circle"></span>
                                                <span>{t('contactus')}</span>
                                            </Link>
                                            <span className="global-border" aria-hidden="true">&nbsp;</span>
                                            </>
                                        }
                                        {(isAuthDataFetchCompleted && isAuthenticated) ?
                                            <>
                                                <UIMenu
                                                    label={t('MyAccount')}
                                                    rightIcon="icon-chevron-down vertical-middle padding-left-1x"
                                                    menuClass="popupmenu top-0x"
                                                    buttonClass="primary tiny"
                                                    className="inline-block"
                                                >
                                                    <a href={t('MyAccount:myAccountLeftNav.firstLinkUrl')} className="left padding-left-1x">{t('MyAccount:myAccountLeftNav.firstLinkTxt')}</a><br />
                                                    <a href={t('MyAccount:myAccountLeftNav.secondLinkUrl')} className="left padding-left-1x">{t('MyAccount:myAccountLeftNav.secondLinkTxt')}</a><br />
                                                    <a href={accountProfileUrl} className="left padding-left-1x">{t('MyAccount:myAccountLeftNav.thirdLinkTxt')}</a><br />
                                                    <div className="left padding-left-1x top-1x bottom-1x">
                                                        <button className="button secondary small" onClick={() => handleLogout()} >
                                                            <span className="icon-log-out"></span><span>{t('Log out')}</span>
                                                        </button>
                                                    </div>
                                                </UIMenu>
                                            </>
                                            :
                                            <button
                                                className="global icon login"
                                                onClick={handleLogin}>
                                                <span aria-hidden="true" className="icon-user"></span>
                                                <span>{t('logIn')}</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>
                <div className="header-reimagined sticky-top show-for-large">
                    <header>
                        <nav id="main-nav" tabIndex="-1">
                            <div className="row body">
                                <div className="columns small-12 medium-12 large-3">
                                    {isAcc ?
                                        <Link to="#" className="logo" title="Florida Blue" aria-label="Florida Blue Home">
                                            <span id="FloridaBlueLogo" className={`${(lang === 'es') ? 'icon-logo icon-fb-es-tagline' : 'icon-logo icon-fb-en-tagline'}`} aria-hidden="true" />
                                        </Link>
                                        :
                                        <Link to={homePagePath} onClick={(e) => marketplaceDisclaimerRef.current.showMarketplaceDisclaimer(homePagePath, {}, e)} className="logo" title="Florida Blue" aria-label="Florida Blue Home">
                                            <span id="FloridaBlueLogo" className={`${(lang === 'es') ? 'icon-logo icon-fb-es-tagline' : 'icon-logo icon-fb-en-tagline'}`} aria-hidden="true" />
                                        </Link>
                                    }
                                </div>
                                <div className="columns small-12 medium-8 large-5 padding-right-0x">
                                    <ul className="nav-list" onClick={clickCallback}>
                                        <li>
                                            <UIMenu
                                                buttonClass={"nav-btn"}
                                                label={t('products')}
                                                menuClass="sub-nav"
                                                rightIcon="icon-chevron-down"
                                                >
                                                <div className="columns small-12 medium-9 end">
                                                    <button className="mega primary" onClick={(e) => handleAction(e, "health")}>
                                                        <span
                                                            className="icon icon-groups icon-size-8x"
                                                            aria-hidden="true"
                                                        />
                                                        <p className="head nowrap padding-top-1x">{t('health')}</p>
                                                    </button>
                                                    <button className="mega primary" onClick={(e) => handleAction(e, "dental")}>
                                                        <span
                                                            className="icon icon-tooth icon-size-8x"
                                                            aria-hidden="true"
                                                        />
                                                        <p className="head nowrap padding-top-1x">{t('dental')}</p>
                                                    </button>
                                                </div>
                                                <div className="columns small-12 medium-3 end">
                                                    <ul>
                                                        <li><span role="separator" className="hl-small">{t('Other Types of Plans')}</span></li>
                                                        <li><Link to={RoutePaths.ACCIDENT} id="accident" className="" onClick={(e) => handleAction(e, "accident")}>{t('accident')}</Link></li>
                                                        <li><Link to={RoutePaths.CRITICAL_ILLNESS} id="ci" className="" onClick={(e) => handleAction(e, "ci")}>{t('ctiticalIllness')}</Link></li>
                                                        <li><Link to={RoutePaths.HOSPITAL} className="" id="hi" onClick={(e) => handleAction(e, "hi")}>{t('USAble Life Hospital')}</Link></li>
                                                        <li><Link to={RoutePaths.INTERNATIONAL} onClick={(e) => marketplaceDisclaimerRef.current.showMarketplaceDisclaimer(RoutePaths.INTERNATIONAL, {}, e)}>{t('internationalTravel')}</Link></li>
                                                    </ul>
                                                </div>
                                            </UIMenu>
                                        </li>
                                        {!isAcc &&
                                            <li>
                                                <Link
                                                    to={isMOT ? RoutePaths.MEMBER_PROFILE : RoutePaths.GET_QUOTE}
                                                    state={{
                                                        zipcode: "",
                                                        navigatedFromCartPage: pathname === RoutePaths.CART,
                                                        navigatedFromPlanListPage: planListURLs.includes(pathname)
                                                    }}
                                                    className='nav-btn'
                                                >
                                                    {(quoteData?.applicants?.length == 0) ? t('getAQuote') : t('editAQuote')}
                                                </Link>
                                            </li>
                                        }
                                        <li>
                                            <UIMenu
                                                buttonClass={"nav-btn"}
                                                label={t('resources')}
                                                menuClass="sub-nav"
                                                rightIcon="icon-chevron-down"
                                                >
                                                <div className="columns small-12 medium-9 end">
                                                    {!isAcc && !isAqt &&
                                                        <Link to={RoutePaths.HELP} className="inline right-1x">
                                                            <button className="mega primary">
                                                                <span
                                                                    className="icon icon-support icon-size-8x"
                                                                    aria-hidden="true"
                                                                />
                                                                <p className="head nowrap">{t('support')}</p>
                                                            </button>
                                                        </Link>
                                                    }
                                                    <a href={t('findADoctorUrl')} target="_blank" className="inline right-1x">
                                                        <button className="mega primary">
                                                            <span
                                                                className="icon icon-doctor icon-size-8x"
                                                                aria-hidden="true"
                                                            />
                                                            <p className="head nowrap">{t('findADoctor')}</p>
                                                        </button>
                                                    </a>
                                                    <a href={`${RoutePaths.HEALTH_CARE_101}?language=${localStorage['i18nextLng']}`} target="_blank" className="inline">
                                                        <button className="mega primary">
                                                            <span
                                                                className="icon icon-assessments icon-size-8x"
                                                                aria-hidden="true"
                                                            />
                                                            <p className="head">{t('healthCare101')}</p>
                                                        </button>
                                                    </a>
                                                </div>
                                                <div className="columns small-12 medium-3 end">
                                                    <ul>
                                                        <li><span role="separator" className="hl-small">{t('otherHelpfulLinks')}</span></li>
                                                        <li><a href={t('frequentlyAskedQuestionsLink')} target="_blank">{t('frequentlyAskedQuestions')}</a></li>
                                                    </ul>    
                                                </div>
                                            </UIMenu>    
                                        </li>
                                    </ul>
                                </div>
                                <div className="columns small-12 medium-3 large-4 text-right padding-left-0x">
                                    {!isAcc && !isAqt && !isMultiEnrollmentGroupApplication && !isMOT && !isPrebill && isAllowedSaveProgressPath(pathname) &&
                                        <button className="primary small" onClick={() => toggleSaveProgressDialog()}>
                                            {t('save')}
                                        </button>
                                    }
                                </div>
                            </div>
                        </nav>
                    </header>
                </div>
            </Fragment>
        );
    }

    const mobileSubMenuData = () => {
        let map = {};   
        
        let main = {
            label: t("navMain"),
            content: (
                <div className="sub-menu">
                    <div className="row">
                        <div className="columns small-12 medium-12">
                            <ul>
                                {!isAcc &&
                                    <li>
                                        <Link to={homePagePath} onClick={(e) => marketplaceDisclaimerRef.current.showMarketplaceDisclaimer(homePagePath, {}, e)} className="naked button menu-desc-btn">{t("navHome")}</Link>
                                    </li>
                                }                                
                                <li><a href={t('findADoctorUrl')} target="_blank" className="naked button menu-desc-btn">{t('findADoctor')}</a></li>                                
                                {!isAcc && !isAqt &&
                                    <li>
                                        <Link to={RoutePaths.HELP} className="naked button menu-desc-btn">{t('contactus')}</Link>
                                    </li>
                                }                                
                                <li>
                                    {(!isAcc || (isAcc && accData?.response?.supportedLangs.indexOf("en,es") !== -1)) &&
                                        <UILanguageSwitcher
                                            className="naked button menu-desc-btn"
                                            altLang={(localStorage['i18nextLng'] === "en") ? "es" : "en-us"}
                                            onLanguageChange={onLanguageChange}
                                        />
                                    }
                                </li>
                                <li>
                                    {isAcc ? (
                                            <span className="left left-1x">
                                                {getAccAgentContent(accData.response.agentInfo)}
                                            </span>
                                        ) : isAqt ? (
                                            <span className="left left-1x">
                                                {getAqtAgentContent(aqtData.response.agentInfo)}
                                            </span>
                                        ) : (
                                            <span className="left-1x">
                                                {t('needhelp')} <a href="tel:1-855-714-8894"><strong>1-855-714-8894</strong></a>
                                            </span>
                                        )
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        };
        map["main"] = main;

        let member = {
          label: t('MyAccount'),
          content:  (
                <div className="sub-menu">
                    <div className="row">
                        <div className="columns small-12 medium-12">
                            <ul>				
                                <li><Link to={t('MyAccount:myAccountLeftNav.firstLinkUrl')} className="naked button menu-desc-btn">{t('MyAccount:myAccountLeftNav.firstLinkTxt')}</Link></li>
                                <li><Link to={t('MyAccount:myAccountLeftNav.secondLinkUrl')} className="naked button menu-desc-btn">{t('MyAccount:myAccountLeftNav.secondLinkTxt')}</Link></li>
                                <li><button className="naked button menu-desc-btn" onClick={() => handleLogout()} >{t('Log out')}</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        };
        map["member"] = member;
        
        let products = {
            label: t('products'),
            content:  (
                  <div className="sub-menu">
                      <div className="row">
                          <div className="columns small-12 medium-12">
                              <ul>				
                                  <li><button className="naked button menu-desc-btn" onClick={(e) => handleAction(e, "health")}>{t('health')}</button></li>
                                  <li><button className="naked button menu-desc-btn" onClick={(e) => handleAction(e, "dental")}>{t('dental')}</button></li>
                                  <li><button className="naked button menu-desc-btn" onClick={(e) => handleAction(e, "accident")}>{t('accident')}</button></li>
                                  <li><button className="naked button menu-desc-btn" onClick={(e) => handleAction(e, "ci")}>{t('ctiticalIllness')}</button></li>
                                  <li><button className="naked button menu-desc-btn" onClick={(e) => handleAction(e, "hi")}>{t('USAble Life Hospital')}</button></li>
                                  <li><Link to={RoutePaths.INTERNATIONAL} onClick={(e) => marketplaceDisclaimerRef.current.showMarketplaceDisclaimer(RoutePaths.INTERNATIONAL, {}, e)} className="naked button menu-desc-btn">{t('internationalTravel')}</Link></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              )
          };
          map["products"] = products;

          let resources = {
            label: t('resources'),
            content:  (
                  <div className="sub-menu">
                      <div className="row">
                          <div className="columns small-12 medium-12">
                              <ul>				
                                  <li>
                                    {!isAcc && !isAqt &&
                                        <Link to={RoutePaths.HELP} className="naked button menu-desc-btn">{t('support')}</Link>
                                    }
                                  </li>
                                  <li><a href={t('findADoctorUrl')} target="_blank" className="naked button menu-desc-btn">{t('findADoctor')}</a></li>
                                  <li><a href={`${RoutePaths.HEALTH_CARE_101}?language=${localStorage['i18nextLng']}`} target="_blank" className="naked button menu-desc-btn">{t('healthCare101')}</a></li>
                                  <li><a href={t('frequentlyAskedQuestionsLink')} target="_blank" className="naked button menu-desc-btn">{t('frequentlyAskedQuestions')}</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              )
          };
          map["resources"] = resources;

            
        return map;
    };

    const mobileMenuCallback = (view) => {
        let openChanged = false;
        let open = view != VIEWS.CLOSED;

        setActiveMobileMenuView((prevState) => {
            let prevOpen = prevState.menuView != VIEWS.CLOSED;
            openChanged = open != prevOpen;
            mobileMenuOpenChanged(open, openChanged);
            return view;
        });
    };

    const mobileMenuOpenChanged = (open, openChanged) => {        
        if (openChanged) {
            setActiveMobileMenuView(VIEWS.CLOSED);
        }
    };

    const mobileSubMenuCallback = (key) => {
        const subMenuData = mobileSubMenuData();
        let data = subMenuData[key];
        console.log("subMenuCallback", key, data);
        if (data) {
            setActiveMobileMenuView(VIEWS.SUBMENU);
            setMobileSubMenu(data);
        } else {
            setActiveMobileMenuView(VIEWS.SUBMENU);
            setMobileSubMenu({ label: key, content: <p>Not found</p> });
        }
    };

    const renderMobileNav = t => {
        let logo = (isAcc ?
            <Link to={"#"} className="logo" title="Florida Blue" aria-label="Florida Blue Home">
                <span id="FloridaBlueLogo" className={`${(lang === 'es') ? 'icon-logo icon-fb-es-tagline' : 'icon-logo icon-fb-en-tagline'}`} aria-label="Florida Blue" />
            </Link>
            :
            <Link to={homePagePath} onClick={(e) => marketplaceDisclaimerRef.current.showMarketplaceDisclaimer(homePagePath, {}, e)} className="logo" title="Florida Blue" aria-label="Florida Blue Home">
                <span id="FloridaBlueLogo" className={`${(lang === 'es') ? 'icon-logo icon-fb-es-tagline' : 'icon-logo icon-fb-en-tagline'}`} aria-hidden="true" />
            </Link>
        );        
        return (
            <Fragment>
                <div className="header-reimagined sticky-top hide-for-large">
                    <MobileMenu
                        menuOpenCallback={mobileMenuCallback}
                        show={activeMobileMenuView}
                        subMenu={mobileSubMenu}         
                        hasUnread={false}
                        logo={logo}
                    >
                        <nav>
                            <ul>                                
                                <li key="main">
                                    <MobileMenuButton
                                        iconClass="icon-home"
                                        label={t("navMain")}
                                        submenuCallback={mobileSubMenuCallback}
                                        hashKey="main"
                                    />
                                </li>
                                <li key="member">
                                    {(isAuthDataFetchCompleted && isAuthenticated) ?
                                        <MobileMenuButton
                                            label={t('MyAccount')}
                                            iconClass="icon-user"
                                            submenuCallback={mobileSubMenuCallback}
                                            hashKey="member"
                                        />
                                    :
                                        <MobileMenuLink
                                            label={t('logIn')}
                                            url={RoutePaths.OVERVIEW}
                                            iconClass="icon-user"
                                        />
                                    }
                                </li>
                                <li key="products">
                                    <MobileMenuButton
                                        iconClass="icon-groups"
                                        label={t('products')}
                                        submenuCallback={mobileSubMenuCallback}
                                        hashKey="products"
                                    />
                                </li>
                                {!isAcc &&
                                    <li>
                                        <Link
                                            to={isMOT ? RoutePaths.MEMBER_PROFILE : RoutePaths.GET_QUOTE}
                                            state={{
                                                zipcode: "",
                                                navigatedFromCartPage: pathname === RoutePaths.CART,
                                                navigatedFromPlanListPage: planListURLs.includes(pathname)
                                            }}
                                            className='naked'
                                        >
                                            <span aria-hidden="true" className="mobile-icon icon-edit"></span>
                                            <span className="mobile-label"><span>{(quoteData?.applicants?.length == 0) ? t('getAQuote') : t('editAQuote')}</span></span>
                                            <span className="icon-chevron-right" aria-hidden="true"></span>
                                        </Link>
                                    </li>
                                }
                                <li key="resources">
                                    <MobileMenuButton
                                        iconClass="icon-resources"
                                        label={t('resources')}
                                        submenuCallback={mobileSubMenuCallback}
                                        hashKey="resources"
                                    />
                                </li>
                                <li key="cart">
                                    <button className="naked" onClick={handleCart}>
                                        <span aria-hidden="true" className="mobile-icon icon-shopping-cart"></span>
                                        <span className="mobile-label">
                                            <span>{t('cart')}</span>
                                        </span>
                                        <span className="icon-chevron-right" aria-hidden="true"></span>
                                    </button>
                                </li>
                                {!isAcc && !isAqt && !isMultiEnrollmentGroupApplication && !isMOT && !isPrebill && isAllowedSaveProgressPath(pathname) &&
                                    <li>
                                        <hr />
                                        <button className="naked" onClick={() => toggleSaveProgressDialog()}>
                                            <span className="mobile-label">
                                                <span>{t('save')}</span>
                                            </span>
                                            <span className="icon-chevron-right" aria-hidden="true"></span>
                                        </button>
                                    </li>
                                }                                    
                            </ul>
                        </nav>
                    </MobileMenu>
                </div>
            </Fragment>
        );
    }
    const renderChatFloatingIcon = t => {
        return (
            <Fragment>
                <>
                    <style>
                        {
                            `
                                .five9ChatButton {
                                    background-color: #FAA83F;   
                                    position: fixed;
                                    bottom: 20px;
                                    right: 20px;
                                    border-radius: 10px;
                                    padding: 10px;
                                    z-index: 100;                                   
                                }																	
                                .five9ChatTxt {
                                    color: #222121;  														      
                                    border-radius: 10px;
                                }
                                .five9ChatButtonTxt {                                    
                                    text-decoration: none !important;
                                }									
                          `
                        }
                    </style>
                    <div id="five9-chat-button" className="hide five9ChatButton">
                        <button className="success linklike chat link-arrow five9ChatButtonTxt" onClick={() => startFiveNineChat()}>
                            <span className="caution vertical-middle larger-1x five9ChatTxt"><strong>{t('Live Chat!')}</strong></span>
                            <span className="icon-chat-assistant vertical-middle larger-4x" />
                        </button>
                    </div>
                    <div id="five9-call-you-back" className="five9ChatButton">
                        <button className="success linklike chat link-arrow" onClick={() => toggleDialogVisibility()}>
                            <span>{t(' ')}</span>
                            <span className="icon-chat-assistant vertical-middle larger-4x" aria-hidden="true" />
                        </button>
                    </div>
                </>
            </Fragment>
        );
    }

    return (
        <Fragment>
            {renderDesktopNav(t)}
            {renderMobileNav(t)}
            {chatEnabled && !isAcc && !isAqt && !isMOT && !(hideHeaderChatPages.includes(pathname)) && !hideChatLinkPrebillCompare &&
                <div className="padding-bottom-1x">
                    { renderChatFloatingIcon(t) }
                </div>
            }
            {callbackEnabled &&
                <CallYouBackPopup />
            }
            <SaveProgressPopup saveProgressModal={saveProgressModal} setSaveProgressModal={setSaveProgressModal} isParent={true}/>
            {callbackEnabled && !isAcc && !isAqt &&
                <ProgressCallYouBack />
            }
            <MarketplaceDisclaimerModal ref={marketplaceDisclaimerRef} />
        </Fragment>
    );
}

export default HeaderReimagined;