import React, {lazy} from "react";
import { Routes, Route } from "react-router-dom";

import HeaderLegacy from "components/Layout/HeaderLegacy";
import HeaderReimagined from "components/Layout/HeaderReimagined";
import Footer from "components/Layout/Footer";
import Landing from "components/Landing/Landing";
import ProtectedRoutes from "./components/Authentication/ProtectedRoutes";

/**
 * Base router configuration that defines all routes in the application.
 * implementation of Lazy loading to reduce the intial bundle size
 * https://react.dev/reference/react/lazy#usage
 */

const GetQuote = lazy(() => import('components/GetQuote/GetQuote'));
// US673343, TODO: cleanup
//const MemberGetQuote = lazy(() => import('components/GetQuote/MemberGetQuote'));
const Subsidy = lazy(() => import('components/Subsidy/Subsidy'));
const ProviderSearch = lazy(() => import('components/ProviderSearch/ProviderSearch'));
const ProviderSearchSelect = lazy(()=> import('components/ProviderSearch/ProviderSearchSelect'));
const MyAccount = lazy(() => import('components/MyAccount/MyAccount'));
const MyAccountOverview = lazy(() => import('components/MyAccount/MyAccountOverview'));
const MyAccountApplications = lazy(() => import('components/MyAccount/MyAccountApplications'));
const Cart = lazy(() => import('components/Cart/Cart'));
const CSRNudge =lazy(() => import('./components/Plans/csr-nudge'));
const Help = lazy(() => import('components/StaticPages/Help'));
const InsurancePlans = lazy(() => import('components/StaticPages/InsurancePlans'));
const DentalLanding = lazy(() => import('components/StaticPages/DentalLanding'));
const HospitalInsurancePlans = lazy(() => import('components/StaticPages/HospitalInsurancePlans'));
const InternationalTravel = lazy(() => import('components/StaticPages/InternationalTravel'));
const Plans = lazy(() => import('./components/Plans/Plans'));
const PlanDetails = lazy(() => import('./components/Plans/PlanDetails'));
const PlanCompare = lazy(() => import('./components/Plans/PlanCompare'));
const CriticalIllnessPlans = lazy(() => import('components/StaticPages/CriticalIllnessPlans'));
const HealthCare101 = lazy(() => import('components/StaticPages/HealthCare101'));
const AccidentPlans = lazy(() => import('components/StaticPages/AccidentPlans'));
const PageNotFound = lazy(() => import('components/StaticPages/PageNotFound'));
const MultiEnrollmentGroups = lazy(() => import('components/OnExchange/MultiEnrollmentGroups'));
const OtherProducts = lazy(() => import('./components/StaticPages/OtherProducts'));
const MwsQhpPreview = lazy(() => import('components/MwsQhp/MwsQhpPreview'));
const OnExStubFlow = lazy(() => import('./components/OnExchange/OnExStubFlow'));
const OnExEnrollmentError = lazy(() => import('./components/OnExchange/OnExEnrollmentError'));
const MwsQhpRenewalThankyou = lazy(() => import('./components/MwsQhp/MwsQhpRenewalThankyou'));
const MotRenewalThankyou = lazy(() => import('./components/GetQuote/MotRenewalThankyou'));
const WhatToExpect = lazy(() => import('./components/StaticPages/WhatToExpect'));
const CallYouBack = lazy(() => import('./components/CallYouBack/CallYouBack'));
const CallYouBackConfirm = lazy(() => import('./components/CallYouBack/CallYouBackConfirm'));
const CallYouBackError = lazy(() => import('./components/CallYouBack/CallYouBackError'));
const Acc = lazy(() => import('./components/ACC/Acc'));
const AccValidate = lazy(() => import('./components/ACC/AccValidate'));
const Aqt = lazy(() => import('./components/AQT/Aqt'));
const ShoppingIneligible = lazy(() => import('./components/StaticPages/ShoppingIneligible'));
const PrebillStubFlow = lazy(() => import('./components/MwsQhp/PrebillStubFlow'));
const ProgressStubFlow = lazy(() => import('./components/Layout/ProgressStubFlow'));
const HealthInsuranceMarketplace = lazy(() => import('components/StaticPages/HealthInsuranceMarketplace'));

import * as Paths from "constants/Routes";
import UnSubscribed from "./components/Progress/unSubscribed";

/**
 * Base router configuration that defines all routes in the application.
 *
 * Using 'react-router(V6)', below are some useful links,
 * { @see https://reactrouter.com/en/6.10.0/start/concepts }
 * { @see https://reactrouter.com/en/main }
 * { @see https://reactrouter.com/docs/en/v6/upgrading/v5 }
 */
function AppRouter() {
  return (
    <div>
      {/* <HeaderLegacy /> */}
      <HeaderReimagined />
      <Routes>
        <Route path={Paths.HOME} element={<Landing marketPlaceReturn={false}/>} />
        <Route path={Paths.MARKETPLACE_RETURN} element={<Landing marketPlaceReturn={true}/>} />
        <Route path={Paths.LOGOUT} element={<Landing logout={true}/>} />
        <Route path={Paths.GET_QUOTE} element={<GetQuote currentStep={'getQuoteSelectPeople'}/>} />
        <Route path={Paths.GET_QUOTE_2} element={<GetQuote currentStep={'getQuoteDemographic'} />} />
        <Route path={Paths.SUBSIDY} element={<Subsidy currentStep={'subsidyDemographic'} />} />
        <Route path={Paths.SUBSIDY_2} element={<Subsidy currentStep={'subsidyPeopleOnQuote'} />} />
        <Route path={Paths.SUBSIDY_3} element={<Subsidy currentStep={'subsidyEstimatedTaxCredit'} />} />
        <Route path={Paths.PLANS} element={<Plans />} />
        <Route path={Paths.PLAN_DETAILS} element={<PlanDetails />} />
        <Route path={Paths.PLANS_COMPARE} element={<PlanCompare />} />
        <Route path={Paths.CSR_NUDGE} element ={<CSRNudge/>} />
        <Route path={Paths.PLANS_ON} element={<Plans />} />
        <Route path={Paths.MULTI_ENROLLMENT_GROUPS} element={<MultiEnrollmentGroups step = {0}/>} />
        <Route path={Paths.MULTI_ENROLLMENT_GROUPS_2} element={<MultiEnrollmentGroups step = {1} />} />
        <Route path={Paths.MULTI_ENROLLMENT_GROUPS_3} element={<MultiEnrollmentGroups step = {2} />} />
        <Route path={Paths.ONEX_STUB_FLOW} element={<OnExStubFlow />} />
        <Route path={Paths.ONEX_ENROLLMENT_ERROR} element={<OnExEnrollmentError />} />
        <Route path={Paths.OTHER_PRODUCTS} element={<OtherProducts />} />
        <Route path={Paths.PROVIDER} element={<ProviderSearch />} />
        <Route path={Paths.SELECT} element={<ProviderSearchSelect />} />
        <Route path={Paths.CART} element={<Cart />} />
        <Route element={<ProtectedRoutes />}>
          {/* US673343 <Route path={Paths.MEMBER_PROFILE} element={<MemberGetQuote />} /> */}
          <Route path={Paths.ACCOUNT} element={<MyAccount />} />
          <Route path={Paths.OVERVIEW} element={<MyAccountOverview />} />
          <Route path={Paths.APPLICATIONS} element={<MyAccountApplications />} />
        </Route>
        <Route path={Paths.INSURANCE_PLANS} element={<InsurancePlans />} />
        <Route path={Paths.SDENTAL} element={<DentalLanding />} />
        <Route path={Paths.HOSPITAL} element={<HospitalInsurancePlans />} />
        <Route path={Paths.CRITICAL_ILLNESS} element={<CriticalIllnessPlans />} />
        <Route path={Paths.ACCIDENT} element={<AccidentPlans />} />
        <Route path={Paths.INTERNATIONAL} element={<InternationalTravel />} />
        <Route path={Paths.HELP} element={<Help />} />
        <Route path={Paths.SHOPPING_INELIGIBLE} element={<ShoppingIneligible />} />
        <Route path={Paths.HEALTH_CARE_101} element={<HealthCare101 />} />
        <Route path={Paths.MWS_QHP_PREVIEW} element={<MwsQhpPreview />} />
        <Route path={Paths.MWS_QHP_RENEWAL_THANK_YOU} element={<MwsQhpRenewalThankyou />} />
        <Route path={Paths.MOT_RENEWAL_THANK_YOU} element={<MotRenewalThankyou />} />
        <Route path={Paths.WHAT_TO_EXPECT} element={<WhatToExpect />} />
        <Route path={Paths.CALL_YOU_BACK} element={<CallYouBack />} />
        <Route path={Paths.CALL_YOU_BACK_CONFIRM} element={<CallYouBackConfirm />} />
        <Route path={Paths.CALL_YOU_BACK_ERROR} element={<CallYouBackError />} />
        <Route path={Paths.ACC_URL} element={<Acc />} />
        <Route path={Paths.ACC_VALIDATE} element={<AccValidate />} />
        <Route path={Paths.AQT_URL} element={<Aqt />} />
        <Route path={Paths.PREBILL_STUB_FLOW} element={<PrebillStubFlow />} />
        <Route path={Paths.PROGRESS_STUB_FLOW} element={<ProgressStubFlow />} />
        <Route path={Paths.Health_Insurance_Market_place} element={<HealthInsuranceMarketplace />} />
        <Route path={Paths.UnsubscribedContent} element={<UnSubscribed/>} />
        {/* Catch all for invalid App URLs */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}
export default AppRouter;
