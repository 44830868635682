import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

export class MobileMenuLink extends Component {
  middle = (label, description, icon) => {
    // let cn = icon ? "columns small-8" : "columns small-10 text-left";
    if (!description) {
      return (
        <span className="mobile-label">
          <span>{label}</span>
        </span>
      );
    }

    return (
      <span className="mobile-label">
        <span>{label}</span>
        <br />
        <span className="desc">{description}</span>
      </span>
    );
  };

  render() {
    const {
      iconClass,
      label,
      description,
    url
    } = this.props;

    let icon = iconClass ? (
      <span className="mobile-icon">
        <span aria-hidden="true" className={iconClass}></span>
      </span>
    ) : undefined;
    let middle = this.middle(label, description, iconClass);

    return (
      <a href={url} className="naked">
          {icon}
          {middle}
          <span className="icon-chevron-right" aria-hidden="true"></span>
      </a>
    );
  }

  static propTypes = {
    iconClass: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string
  };
}