import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import uuid from "uuid-random";

import { translator } from "digital-shared-ui";
import { MobileSubMenuHeader } from "./MobileSubMenuHeader";

const resource = {
  closeMenu: { en: "close menu", es: "cerrar el menú" },
  openMenu: { en: "open menu", es: "menú abierto" },
  searchMenu: { en: "search", es: "buscar" },
  openMenuWithUnread: {en:"open menu. you have unread messages", es:"menú abierto. tienes mensajes sin leer"}
};

export const VIEWS = {
  // 3 VIEW options, CLOSED, OPEN or SUBMENU
  CLOSED: "closed",
  OPEN: "open",
  SUBMENU: "submenu",
};

export class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.translator = translator(resource);
    this.state = { view: props.show, submenu: props.subMenu };
    this.id = uuid();    
  }

  componentDidUpdate() {
    if (this.state.view != VIEWS.SUBMENU) {
      // The user opened/closed the menu by clicking a button in a menu header
      // move focus to the button in the menu header
      document.getElementById(this.id).focus();
    }
  }

  openMenu = () => {
    // Action to open the menu
    this.setState({ view: VIEWS.OPEN, submenu: null });
    this.props.menuOpenCallback(VIEWS.OPEN);
  };

  closeMenu = () => {
    // Action to close the menu
    this.setState({ view: VIEWS.CLOSED, submenu: null });
    this.props.menuOpenCallback(VIEWS.CLOSED);
  };

  static getDerivedStateFromProps(props, currentState) {
    if (props.show != currentState.view) {
      // If the props changed update state
      return { view: props.show, submenu: props.subMenu };
    }
    // No update of state needed
    return {};
  }

  renderClosedMenu = () => {
    // Render the closed menu which is only the header
    const { logo, hasUnread } = this.props;
    let unreadIcon = hasUnread ? <span className="icon icon-alert-dot" aria-hidden="true"></span> : undefined ;
    let ariaLabel = hasUnread ? this.translator.translate("openMenuWithUnread") : this.translator.translate("openMenu"); 
    

    return (
      <header className="mobile-menu">
        <div className="row top-bar">
          <div className="columns small-10">{logo}</div>
          <div className="columns small-2 text-right">
            <button
              aria-label={ariaLabel}
              className="mobile-menu-toggle"
              aria-expanded="false"
              onClick={this.openMenu}
              id={this.id} 
              >
              <span aria-hidden="true" className="icon-menu-mobile" />             
              {unreadIcon}
            </button>
            
          </div>
        </div>
      </header>
    );
  };

  renderSubMenu = (obj) => {

    // console.log("renderSubMenu", obj);
    if (!obj) return null;
    // Render the sub menu, the submenu contents is passed as a prop
    return (
      <Fragment>
        <MobileSubMenuHeader
          label={obj.label}
          backCallback={this.openMenu}
          closeCallback={this.closeMenu}
        >
          {obj.content}
        </MobileSubMenuHeader>
      </Fragment>
    );
  };

  render() {
    const { logo, children } = this.props;
    const { view, submenu } = this.state;
    

    if (view == VIEWS.CLOSED) {
      return this.renderClosedMenu();
    }

    if (view == VIEWS.OPEN) {
      // render the open menu
      return (
        <Fragment>
          <header className="mobile-menu active">
            <div className="row top-bar">
              <div className="columns small-10">{logo}</div>
              <div className="columns small-2 text-center">
                <button
                  aria-label={this.translator.translate("closeMenu")}
                  className="mobile-menu-toggle"
                  onClick={this.closeMenu}
                  id={this.id}  
                >
                  <span aria-hidden="true" className="icon-close" /> 
                 
                  
                </button>
              </div>
            </div>
            {children}
          </header>
        </Fragment>
      );
    }

    return this.renderSubMenu(submenu);
  }

  static defaultProps = {
    show: VIEWS.CLOSED,
    // Convience, unlikely to be correct
    logo: (
      <a target="_top" className="logo" href="index.html" title="Florida Blue" aria-label="Florida Blue Home">
        <span className="icon-logo icon-fb-en-tagline" aria-hidden="true"></span>    
      </a>
    ),
  };

  static propTypes = {
    // The logo, may be a link or just the logo
    logo: PropTypes.node,
    menuOpenCallback: PropTypes.func,
    subMenu: PropTypes.object,
    show: PropTypes.oneOf([VIEWS.CLOSED, VIEWS.OPEN, VIEWS.SUBMENU]),
  };
}