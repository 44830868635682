import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const LOCALE_DEFAULT = "en";
export const LOCALE_COOKIE_NAME = 'SMLOCALE' ;
export const LOCALE_COOKIE_DOMAIN = window.location.hostname.split('.').slice(-2).join('.');

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', (language) => {
    document.documentElement.lang = language;
    document.cookie = LOCALE_COOKIE_NAME + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + LOCALE_COOKIE_DOMAIN;
    document.cookie = LOCALE_COOKIE_NAME + "=" + language + "; path=/; domain=" + LOCALE_COOKIE_DOMAIN;
  })
  .init({
    supportedLngs: ['en', 'es'],
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'language',
      lookupCookie: LOCALE_COOKIE_NAME,
      cookieDomain: LOCALE_COOKIE_DOMAIN,
      cookieOptions: { path: '/', sameSite: 'strict' }
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    fallbackLng: LOCALE_DEFAULT,
    ns: ['Layout'],
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;